<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ sprintf($t('pages.system.notification.item.template.titlePattern'), [item.name ?? '']) }}
        </h2>
        <router-link :to="sprintf('/system/notification/group/%d/sub-group/%d/item', [groupID, subGroup])" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.system.notification.item.title") }}
        </router-link>
    </div>

    <el-form :model="form" ref="templateForm">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body p-0">
                        <!--<el-menu :default-active="menuModel" class="el-menu" mode="horizontal" @select="handleSelect" :ellipsis="false">
                            <el-menu-item index="general" class="w-100">{{ $t('pages.system.notification.item.template.tabs.general') }}</el-menu-item>
                        </el-menu>-->
                        <div class="py-8 px-9">
                            <div class="fv-row mb-5">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.notification.item.template.cols.content') }}</label>
                                <el-form-item prop="translate.content" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.translate.content" type="textarea" rows="10"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mt-5">
                                <el-form-item class="mb-0 text-center">
                                    <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100 w-md-25" type="button">
                                        <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                        <span v-if="form.loading" class="indicator-progress">
                                            {{ $t("messages.wait") }}
                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <p class="text-gray-800 fs-6 mt-1 mb-7" v-html="sprintf($t('pages.system.notification.item.template.warning.variable'), [('<b>{{' + variables[0] + '}}</b>')])"></p>
                        <div class="variable-box">
                            <div class="d-flex flex-stack border-top border-gray-300 border-top-dashed py-3" :class="{'border-0': variableIndex == 0, 'pb-0': (variableIndex + 1) == variables.length}" v-for="(variable, variableIndex) in variables" :key="variableIndex" v-html="sprintf('{{%s}}', [variable])"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "index",
    components: {ElSelectTree},
    data() {
        return {
            menuModel: 'general',
            templates: [],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                translate: {},
                data: {}
            },
            item: {},
            variables: ['user_firstname', 'user_lastname', 'user_email', 'user_mobile_phone', 'product_title']
        }
    },
    computed: {
        groupID() {
            return this.$route.params.sub_group_id;
        },
        subGroup() {
            return this.$route.params.group_id;
        },
        itemID() {
            return this.$route.params.item_id;
        }
    },
    created() {
        if (!this.itemID || !(this.itemID > 0)) {
            this.$router.push({
                path: this.sprintf('/system/notification/group/%d/sub-group/%d/item', [this.groupID, this.subGroup])
            });
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.system.notification.item.template.title"), [this.$t("menu.systemManagement"), this.$t("pages.system.notification.item.group.title")]);

        if (this.itemID && this.itemID > 0) {
            this.loadItem();
        }
    },
    methods: {
        loadItem() {
            this.axios.get(this.endpoints['notification_item'] + '/' + this.itemID).then((response) => {
                let data = response.data.data;
                this.item = data;

                /*this.templates = data.templates.map((template) => {
                    template.translate = this.resolveDatum(template.translations, this.$root.defaultLanguage.id, 'language_id');
                    return template;
                });*/

                this.resetFormData();

                //Manipulation Start
                let template = data.template;

                this.form.translate = this.resolveDatum(template.translations, this.$root.defaultLanguage.id, 'language_id');
                this.form.data = template;
                //Manipulation End
            }).catch((error) => {
                if (error.response.data.message == 'record_not_found') {
                    this.$router.push({
                        path: this.sprintf('/system/notification/group/%d/sub-group/%d/item', [this.groupID, this.subGroup])
                    });
                }
            });
        },
        onSubmit() {
            this.$refs.templateForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareFormData();

                    if (!formData.content.trim().length) {
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.system.notification.item.template.warning.requiredContent"),
                        })
                        return false;
                    }

                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['notification_item_template'] + '/' + formData.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadItem();
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    } else {
                        this.axios.post(this.endpoints['notification_item_template'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadItem();
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data);
                        }).finally(() => {
                            this.form.loading = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = this.form.data;

            return {...this.form.translate, ...formData}
        },
        resetFormData() {
            this.form.translate = {
                language_id: this.$root.defaultLanguage.id
            };

            this.form.data = {
                item_id: this.itemID
            };
        }

    }
}
</script>

<style>
.el-menu {
    border-radius: 0.475rem;
}

.el-menu li:first-child {
    border-top-left-radius: 0.475rem;
}

.el-menu li:last-child {
    border-top-right-radius: 0.475rem;
}
</style>